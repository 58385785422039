/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "assets/vendor/nucleo/css/nucleo.css";
import "assets/scss/argon-design-system-react.scss?v1.1.0";

import FirebaseProvider from "Firebase.js";

import Download from "views/pages/Download.js";
import DeleteData from "views/pages/DeleteData.js";
import EmailVerification from "views/pages/EmailVerification.js";
import Faq from "views/pages/Faq.js";
import Game from "views/pages/Game.js";
import Landing from "views/pages/Landing.js";
import Privacy from "views/pages/Privacy.js";
import Purchase from "views/pages/Purchase.js";
import TermsAndConditions from "views/pages/TermsAndConditions.js";
import TestPage from "views/pages/TestPage.js";

ReactDOM.render(
  <FirebaseProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact render={(props) => <Landing {...props} />} />
        {/* <Route path="/testpage" exact render={props => <TestPage {...props} />} /> */}
        <Route
          path="/download"
          exact
          render={(props) => <Download {...props} />}
        />
        <Route
          path="/deletedata"
          exact
          render={(props) => <DeleteData {...props} />}
        />
        <Route
          path="/emailconfirmation"
          exact
          render={(props) => <EmailVerification {...props} />}
        />
        <Route path="/faq" exact render={(props) => <Faq {...props} />} />
        <Route path="/game" exact render={(props) => <Game {...props} />} />
        <Route
          path="/dhu0AF0Yvv"
          exact
          render={(props) => <Game {...props} />}
        />
        <Route
          path="/privacy"
          exact
          render={(props) => <Privacy {...props} />}
        />
        <Route
          path="/purchase"
          exact
          render={(props) => <Purchase {...props} />}
        />
        <Route
          path="/termsofuse"
          exact
          render={(props) => <TermsAndConditions {...props} />}
        />
        <Redirect to="/" />
      </Switch>
    </BrowserRouter>
  </FirebaseProvider>,
  document.getElementById("root")
);
