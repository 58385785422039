/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import {
  isBrowser
} from "react-device-detect";
// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

class SimpleFooter extends React.Component {
  render() {
    return (
      <>
        <footer className=" footer">
          <Container>
            <Row className=" row-grid align-items-center mb-5">
              <Col lg="6">
                <h3 className=" text-primary font-weight-light mb-2">
                  Thank you for supporting us!
                </h3>
                <h4 className=" mb-0 font-weight-light">
                  Let's get in touch on any of these platforms.
                </h4>
              </Col>
              <Col className="text-lg-center btn-wrapper" lg="6">
                <Button
                  className="btn-icon-only rounded-circle"
                  color="primary"
                  href="https://discord.gg/75UF4TZjQ9"
                  id="tooltip475038074"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fab fa-discord" />
                  </span>
                </Button>
                {
                  isBrowser &&
                  <UncontrolledTooltip delay={0} target="tooltip475038074">
                    Join us on Discord
                  </UncontrolledTooltip>
                }
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="instagram"
                  href="https://www.instagram.com/spyonline.io"
                  id="tooltip837440414"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fab fa-instagram" />
                  </span>
                </Button>
                {
                  isBrowser &&
                  <UncontrolledTooltip delay={0} target="tooltip837440414">
                  Follow us on Instagram
                  </UncontrolledTooltip>
                }
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="twitter"
                  href="https://twitter.com/SpyOnline_io"
                  id="tooltip829810202"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="fab fa-twitter" />
                  </span>
                </Button>
                {
                  isBrowser &&
                  <UncontrolledTooltip delay={0} target="tooltip829810202">
                    Follow us on Twitter
                  </UncontrolledTooltip>
                }
                <Button
                  className="btn-icon-only rounded-circle ml-1"
                  color="github"
                  href="mailto: spyonline.hello@gmail.com"
                  id="tooltip495507257"
                  target="_blank"
                >
                  <span className="btn-inner--icon">
                    <i className="far fa-envelope" />
                  </span>
                </Button>
                {
                  isBrowser &&
                  <UncontrolledTooltip delay={0} target="tooltip495507257">
                    Email us
                  </UncontrolledTooltip>
                }
              </Col>
            </Row>
            <hr />
            <Row className=" align-items-center justify-content-md-between">
              <Col md="6">
                <div className=" copyright">
                  © {new Date().getFullYear()}{" "}
                  <a
                    href="/"
                    target="_blank"
                  >
                    Spy Online
                  </a>
                </div>
              </Col>
              <Col md="6">
                <Nav className=" nav-footer justify-content-end">
                  <NavItem>
                    <NavLink
                      href="/privacy"
                      target="_blank"
                    >
                      Privacy Policy
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="/termsofuse"
                      target="_blank"
                    >
                      Terms of Use
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default SimpleFooter;
