import React, { createContext } from 'react';
// Firebase App (the core Firebase SDK) is always required and must be listed first
import app from "firebase/app";
// If you are using v7 or any earlier version of the JS SDK, you should import firebase using namespace import
// import * as firebase from "firebase/app"

// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import "firebase/analytics";

const FirebaseContext = createContext(null)
export { FirebaseContext }

export default ({ children }) => {
    // For Firebase JS SDK v7.20.0 and later, measurementId is optional
    const firebaseConfig = {
        apiKey: "AIzaSyAtlgauffLqKYjVShHi4xOHlgIoTqEe1EU",
        authDomain: "spy-online-c2709.firebaseapp.com",
        projectId: "spy-online-c2709",
        storageBucket: "spy-online-c2709.appspot.com",
        messagingSenderId: "585621508073",
        appId: "1:585621508073:web:953cbfbead254661d30578",
        measurementId: "G-052XLV16JY"
    };
    app.initializeApp(firebaseConfig);
    app.analytics();
    return (
        <FirebaseContext.Provider value={ app }>
        { children }
        </FirebaseContext.Provider>
    )
}