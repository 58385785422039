/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Download extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-120">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="shape-container d-flex align-items-center py-lg-md">
                <div className="col px-0">
                  <Row className="align-items-center justify-content-center">
                    <Col className="text-center" lg="6">
                      <h1 className="display-3 text-white">
                        Supported Platforms{" "}
                      </h1>
                      <div className="btn-wrapper">
                        <Button
                            className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                            color="default"
                            href="/game"
                            disabled
                        >
                            <span className="btn-inner--icon mr-1">
                                <i className="fas fa-gamepad" />
                            </span>
                            <span className="btn-inner--text">
                                Browser
                            </span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="/download"
                          disabled
                        >
                            <span className="btn-inner--icon mr-1">
                            <i className="fab fa-apple" />
                            </span>
                            <span className="btn-inner--text">
                            App Store
                            </span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="/download"
                          disabled
                        >
                            <span className="btn-inner--icon mr-1">
                            <i className="fab fa-google-play" />
                            </span>
                            <span className="btn-inner--text">
                            Google Play
                            </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
            </section>
            <section className="section section-lg">
                <Container>
                <Row className="justify-content-center text-center mb-md">
                    <Col lg="8">
                    <h2 className="display-3">Coming soon...</h2>
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col className="text-center" lg="6">
                        <div className="btn-wrapper">
                            <Button
                                className="btn-white btn-icon btn-lg mb-4 mb-sm-0 ml-1"
                                color="default"
                                href="/download"
                                disabled
                            >
                                <span className="btn-inner--icon mr-1">
                                <i className="fab fa-apple" />
                                </span>
                                <span className="btn-inner--text">
                                App Store
                                </span>
                            </Button>
                            <Button
                                className="btn-white btn-icon btn-lg mb-3 mb-sm-0 ml-1"
                                color="default"
                                href="/download"
                                disabled
                            >
                                <span className="btn-inner--icon mr-1">
                                <i className="fab fa-google-play" />
                                </span>
                                <span className="btn-inner--text">
                                Google Play
                                </span>
                            </Button>
                        </div>
                    </Col>
                </Row>
                </Container>
            </section>
          </div>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Download;
