/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const queryString = require('query-string');

class Purchase extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    /* example url https://spyonline.io/purchase/?orderId=4032622149712607334&playfabId=D2A668366B0E4A6C */
    const parsed = queryString.parse(window.location.search);

    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-120">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="shape-container d-flex align-items-center py-lg-md">
                <div className="col px-0">
                  <Row className="align-items-center justify-content-center">
                    <Col className="text-center" lg="10">
                      <h1 className="display-3 text-white">
                        Thank you for your purchase!{" "}
                      </h1>
                      <p className="lead text-white">
                        User Id: {parsed.playfabId}
                      </p>
                      <p className="lead text-white">
                        Order Id: {parsed.orderId}
                      </p>
                      <p className="lead text-white">
                        If you encountered any error, please screenshot this page and contact our support team via any of the platforms below.
                      </p>
                    </Col>
                  </Row>
                </div>
              </Container>
            </section>
          </div>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Purchase;
