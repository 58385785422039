/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

const queryString = require('query-string');

class EmailVerification extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    /* https://spyonline.io/emailconfirmation/?token=DEC884F3B067AA49&templateId=614CFC33A7C5DD2D&errorCode=EmailConfirmationTokenDoesNotExist */
    /* https://spyonline.io/emailconfirmation/?token=DEC884F3B067AA49&templateId=614CFC33A7C5DD2D&errorCode=EmailConfirmationTokenExpired */
    /* https://spyonline.io/emailconfirmation/?token=8A3F28792C2D179E&lang=en */

    const parsed = queryString.parse(window.location.search);
    var msg = "";
    if (parsed.errorCode != null) {
        if (parsed.errorCode === "EmailConfirmationTokenDoesNotExist") {
            msg = "Your email has already been confirmed.";
        } else if (parsed.errorCode === "EmailConfirmationTokenExpired") {
            msg = "Error: your confirmation email has already expired! Please try to log in and resend an email to verify.";
        }
    } else {
        msg = "Welcome to Spy Online! Your email has been successfully confirmed.";
    }

    var header = <h1 className="display-3 text-white"> {msg} </h1>;

    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-120">
              <div className="shape shape-style-1 shape-default">
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
                <span />
              </div>
              <Container className="shape-container d-flex align-items-center py-lg-md">
                <div className="col px-0">
                  <Row className="align-items-center justify-content-center">
                    <Col className="text-center" lg="10">
                      {header}
                    </Col>
                  </Row>
                </div>
              </Container>
            </section>
          </div>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default EmailVerification;
