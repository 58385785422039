/*!
=========================================================
* Argon Design System React - v1.1.0
=========================================================
* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)
* Coded by Creative Tim
=========================================================
* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from "react";

class TestPage extends React.Component {
  componentDidMount() {
    var options = {                                             
        zoneId: 4006, devId: 5010, gameId: 6088,
     }
    window.invokeApplixirVideoUnit(options);       // Invoke Video ad
  }
  render() {
    return (
      <>
      <div id="applixir_vanishing_div" hidden>
        <iframe id="applixir_parent" allow="autoplay"></iframe>
    </div>
      </>
    );
  }
}

export default TestPage;