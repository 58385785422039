/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactPlayer from "react-player";

// reactstrap components
import { Badge, Button, Card, Container, Row, Col } from "reactstrap";

import { isBrowser } from "react-device-detect";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

class Landing extends React.Component {
  state = {};
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.main.scrollTop = 0;
  }
  render() {
    return (
      <>
        <DemoNavbar />
        <main ref="main">
          <div className="position-relative">
            {/* shape Hero */}
            <section className="section section-lg section-shaped pb-140">
              <div
                style={{ "background-color": "#6bb0b7" }}
                className="shape shape-style-1"
              >
                <img
                  alt="..."
                  style={{
                    width: "100%",
                  }}
                  src={require("assets/img/brand/banner_background.jpg")}
                />
              </div>
              <Container className="shape-container d-flex align-items-center py-lg-md">
                <div className="col px-0">
                  <Row className="align-items-center justify-content-center">
                    <Col className="text-center" lg="10">
                      <div
                        style={{
                          width: "20vw",
                          margin: "auto",
                          "min-width": "200px",
                        }}
                      >
                        <img
                          alt="..."
                          className="img-fluid"
                          src={require("assets/img/brand/spyonline_logo.png")}
                        />
                      </div>
                      <div
                        className="btn-wrapper"
                        style={{ "margin-top": "50px" }}
                      >
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-5 ml-1"
                          color="default"
                          href="https://discord.gg/75UF4TZjQ9"
                          target="_blank"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fab fa-discord" />
                          </span>
                          <span className="btn-inner--text">JOIN DISCORD</span>
                        </Button>
                      </div>
                      <div className="btn-wrapper">
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="/game"
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-gamepad" />
                          </span>
                          <span className="btn-inner--text">Play</span>
                        </Button>
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="default"
                          href="/download"
                          disabled
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-mobile-alt" />
                          </span>
                          <span className="btn-inner--text">Download</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Container>
              {/* SVG separator */}
              <div className="separator separator-bottom separator-skew">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  preserveAspectRatio="none"
                  version="1.1"
                  viewBox="0 0 2560 100"
                  x="0"
                  y="0"
                >
                  <polygon
                    className="fill-white"
                    points="2560 0 2560 100 0 100"
                  />
                </svg>
              </div>
            </section>
            {/* 1st Hero Variation */}
          </div>
          <section className="section section-sm">
            <Container>
              <Row className="justify-content-center text-center">
                <Col className="text-center" lg="10">
                  <h2 className="display-5">
                    online multiplayer social deduction game{" "}
                  </h2>
                </Col>
                <Col className="text-center" lg="10">
                  <h4>
                    Each player is either an agent or a spy. However no one
                    knows their role, so they will have to use deduction,
                    teamwork, and bluffing skills to win. Agents try to identify
                    the spies while the spies try to hide and bluff their way
                    out.
                  </h4>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-sm">
            <Container>
              <Row className="justify-content-center text-center">
              <div style={{position: "relative", 
 "width": "90vw",
 "height": "50.85vw", "max-wdith": "1040px", "max-height": "584px" }}>
                <ReactPlayer
                  width="100%"
                  height="100%"
                  url="https://www.youtube.com/watch?v=t6rmKmjLNWE"
                  controls={true}
                />
                </div>
              </Row>
            </Container>
          </section>
          <section className="section-sm">
            <Container>
              <Row className="row-grid align-items-center">
                <Col
                  style={{ width: "80%", height: "80%", margin: "auto" }}
                  className="order-md-2"
                  md="6"
                >
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/brand/regular_mode_describe.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="fas fa-user-secret" />
                    </div>
                    <h3>Regular Mode</h3>
                    <p className="lead">
                      <span style={{ color: "#4770a8", fontWeight: "bold" }}>
                        Agents
                      </span>{" "}
                      receive the same secret word while{" "}
                      <span style={{ color: "#eb4646", fontWeight: "bold" }}>
                        spies
                      </span>{" "}
                      receive a slightly different word (ex:{" "}
                      <span style={{ color: "#4770a8", fontWeight: "bold" }}>
                        lemon
                      </span>{" "}
                      vs.{" "}
                      <span style={{ color: "#eb4646", fontWeight: "bold" }}>
                        lime
                      </span>
                      ). No one knows their role, including the spies.
                    </p>
                    <p className="lead">
                      Each round players describe their secret words, discuss
                      with each other based on the clues, and vote out a player.
                      As an agent, your mission is to{" "}
                      <span style={{ color: "#4770a8", fontWeight: "bold" }}>
                        identify yourself as an agent and catch the spies
                      </span>
                      . As a spy, your mission is to{" "}
                      <span style={{ color: "#eb4646", fontWeight: "bold" }}>
                        stay cool, identify yourself as a spy and blend in
                      </span>
                      .
                    </p>

                    <p className="lead">
                      As a lemon agent, can you decipher the clues and find out
                      one of the spies?
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fas fa-search" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Easy to learn, hard to master
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fas fa-users" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">4 - 8 Players</h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="far fa-clock" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">5 - 10 minutes</h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section bg-secondary">
            <Container>
              <Row className="row-grid align-items-center">
                <Col
                  style={{ width: "80%", height: "80%", margin: "auto" }}
                  md="6"
                >
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/brand/regular_mode_vote.png")}
                  />
                </Col>
                <Col md="6">
                  <div className="pl-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-warning shadow rounded-circle mb-5">
                      <i className="ni ni-chart-bar-32" />
                    </div>
                    <h3>Cast your vote!</h3>
                    <p className="lead">
                      Voting is another important part of the game, where the
                      most voted player gets eliminated. If there's a tie, we
                      proceed to a tie break round and let the rest of the folks
                      to decide their fate.
                    </p>
                    <ul className="list-unstyled mt-5">
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fas fa-user-secret" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              Better luck next time{" "}
                              <span
                                style={{ color: "#eb4646", fontWeight: "bold" }}
                              >
                                spy Lucas
                              </span>
                              !
                            </h6>
                          </div>
                        </div>
                      </li>
                      <li className="py-2">
                        <div className="d-flex align-items-center">
                          <div>
                            <Badge
                              className="badge-circle mr-3"
                              color="success"
                            >
                              <i className="fas fa-question" />
                            </Badge>
                          </div>
                          <div>
                            <h6 className="mb-0">
                              There are 2 spies in this room. Can you find out
                              who's the other spy based on the voting result?
                            </h6>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section-sm">
            <Container>
              <Row className="row-grid align-items-center">
                <Col
                  style={{ width: "80%", height: "80%", margin: "auto" }}
                  className="order-md-2"
                  md="6"
                >
                  <img
                    alt="..."
                    className="img-fluid floating"
                    src={require("assets/img/brand/agent_win.png")}
                  />
                </Col>
                <Col className="order-md-1" md="6">
                  <div className="pr-md-5">
                    <div className="icon icon-lg icon-shape icon-shape-success shadow rounded-circle mb-5">
                      <i className="fas fa-trophy" />
                    </div>
                    <h3>Congratulations!</h3>
                    <p className="lead">
                      You have successfully graduated from the academy of Spy
                      Online :)
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
          <section className="section section-sm">
            <Container>
              <Card className="bg-gradient-primary shadow-lg border-0">
                <div className="p-5">
                  <Row className="justify-content-center text-center mb-sm">
                    <Col lg="8">
                      <h2 className="text-white display-3">
                        READY TO HAVE FUN?
                      </h2>
                      <div className="btn-wrapper">
                        {isBrowser && (
                          <Button
                            className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                            color="primary"
                            href="/game"
                          >
                            <span className="btn-inner--icon mr-1">
                              <i className="fas fa-gamepad" />
                            </span>
                            <span className="btn-inner--text">Play</span>
                          </Button>
                        )}
                        <Button
                          className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                          color="primary"
                          href="/download"
                          disabled
                        >
                          <span className="btn-inner--icon mr-1">
                            <i className="fas fa-mobile-alt" />
                          </span>
                          <span className="btn-inner--text">Download</span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Container>
          </section>
          <section className="section section-lg">
            <Container>
              <Row className="justify-content-center text-center mb-lg">
                <Col lg="8">
                  <h2 className="display-3">Meet our team!</h2>
                  <p className="lead text-muted">
                    A team of aspiring indie developers and designers.
                  </p>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/brand/avatar6.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Johnson C.</span>
                        <small className="h6 text-muted">Game Developer</small>
                      </h5>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/brand/avatar9.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Kayci W.</span>
                        <small className="h6 text-muted">
                          Game Developer / Designer
                        </small>
                      </h5>
                    </div>
                  </div>
                </Col>
                <Col className="mb-5 mb-lg-0" lg="3" md="6">
                  <div className="px-4">
                    <img
                      alt="..."
                      className="img-center img-fluid"
                      src={require("assets/img/brand/avatar3.png")}
                      style={{ width: "200px" }}
                    />
                    <div className="pt-4 text-center">
                      <h5 className="title">
                        <span className="d-block mb-1">Jennifer S.</span>
                        <small className="h6 text-muted">Artist</small>
                      </h5>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </main>
        <SimpleFooter />
      </>
    );
  }
}

export default Landing;
