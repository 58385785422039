/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Link } from "react-router-dom";
import { BrowserView, MobileView } from "react-device-detect";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from "headroom.js";
// reactstrap components
import {
  Button,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class DemoNavbar extends React.Component {
  componentDidMount() {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  }
  state = {
    collapseClasses: "",
    collapseOpen: false,
  };

  onExiting = () => {
    this.setState({
      collapseClasses: "collapsing-out",
    });
  };

  onExited = () => {
    this.setState({
      collapseClasses: "",
    });
  };

  render() {
    return (
      <>
        <header className="header-global">
          <Navbar
            className="navbar-main navbar-transparent navbar-light headroom"
            expand="lg"
            id="navbar-main"
          >
            <Container>
              <NavbarBrand className="mr-lg-4" to="/" tag={Link}>
                <img alt="..." src={require("assets/img/brand/BWLogo.png")} />
              </NavbarBrand>
              <button className="navbar-toggler" id="navbar_global">
                <span className="navbar-toggler-icon" />
              </button>
              <UncontrolledCollapse
                toggler="#navbar_global"
                navbar
                className={this.state.collapseClasses}
                onExiting={this.onExiting}
                onExited={this.onExited}
              >
                <div className="navbar-collapse-header">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/">
                        <img
                          alt="..."
                          src={require("assets/img/brand/BWLogo.png")}
                        />
                      </Link>
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button className="navbar-toggler" id="navbar_global">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="navbar-nav-hover align-items-lg-center" navbar>
                  <NavItem>
                    <a className="nav-link nav-link-icon" href="/">
                      <i className="fas fa-home d-lg-none mr-1" />
                      <span className="nav-link-inner--text">Home</span>
                    </a>
                  </NavItem>
                  <NavItem>
                    <a className="nav-link nav-link-icon" href="/faq">
                      <i className="fas fa-question-circle d-lg-none mr-1" />
                      <span className="nav-link-inner--text">FAQ</span>
                    </a>
                  </NavItem>
                </Nav>
                <Nav className="align-items-lg-center ml-lg-auto" navbar>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://discord.gg/75UF4TZjQ9"
                      id="tooltip333589074"
                      target="_blank"
                    >
                      <i className="fab fa-discord" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Discord
                      </span>
                    </NavLink>
                    <BrowserView>
                      <UncontrolledTooltip delay={0} target="tooltip333589074">
                        Join us on Discord
                      </UncontrolledTooltip>
                    </BrowserView>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://www.instagram.com/spyonline.io"
                      id="tooltip356693867"
                      target="_blank"
                    >
                      <i className="fab fa-instagram" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Instagram
                      </span>
                    </NavLink>
                    <BrowserView>
                      <UncontrolledTooltip delay={0} target="tooltip356693867">
                        Follow us on Instagram
                      </UncontrolledTooltip>
                    </BrowserView>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="https://twitter.com/SpyOnline_io"
                      id="tooltip184698705"
                      target="_blank"
                    >
                      <i className="fab fa-twitter" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Twitter
                      </span>
                    </NavLink>
                    <BrowserView>
                      <UncontrolledTooltip delay={0} target="tooltip184698705">
                        Follow us on Twitter
                      </UncontrolledTooltip>
                    </BrowserView>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className="nav-link-icon"
                      href="mailto: spyonline.hello@gmail.com"
                      id="tooltip184698706"
                      target="_blank"
                    >
                      <i className="far fa-envelope" />
                      <span className="nav-link-inner--text d-lg-none ml-2">
                        Email
                      </span>
                    </NavLink>
                    <BrowserView>
                      <UncontrolledTooltip delay={0} target="tooltip184698706">
                        Send us an email
                      </UncontrolledTooltip>
                    </BrowserView>
                  </NavItem>
                  <BrowserView>
                    <NavItem className="d-none d-lg-block ml-lg-4">
                      <Button
                        className="btn-neutral btn-icon"
                        color="default"
                        href="/game"
                        target="_blank"
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-gamepad mr-2" />
                        </span>
                        <span className="nav-link-inner--text ml-1">Play</span>
                      </Button>
                    </NavItem>
                  </BrowserView>
                  <MobileView>
                    <NavItem className="d-none d-lg-block ml-lg-4">
                      <Button
                        className="btn-neutral btn-icon"
                        color="default"
                        href="/download"
                        target="_blank"
                        disabled
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-mobile-alt mr-2" />
                        </span>
                        <span className="nav-link-inner--text ml-1">
                          Download
                        </span>
                      </Button>
                    </NavItem>
                  </MobileView>
                </Nav>
              </UncontrolledCollapse>
            </Container>
          </Navbar>
        </header>
      </>
    );
  }
}

export default DemoNavbar;
