import React from "react";

import {
  Button,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import SimpleFooter from "components/Footers/SimpleFooter.js";

import {
  isMobile
} from "react-device-detect";

import Unity, { UnityContent } from "react-unity-webgl";

const unityContent = new UnityContent(
  "Build/Build.json",
  "Build/UnityLoader.js"
);

class Game extends React.Component {
  componentDidMount() {
    // initiate the event handler
    window.addEventListener("resize", this.resize);
    // const script = document.createElement("script");
    // script.src = "https://cdn.applixir.com/applixir.sdk3.0m.js";
    // script.async = true;
    // document.body.appendChild(script);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resize);
  }

  resize = () => {
    if (isMobile) {
      return;
    }
    // Hacky solution to resize the element
    const unityElement = document.getElementById("unity");
    // const height = parseFloat(window.getComputedStyle(unityElement).height);
  
    // unityElement.setAttribute("style", "width: " + (height / 9 * 16) + "px; height: 100vh; margin: auto");
    unityElement.setAttribute("style", "width: 100vw; height: 100vh; margin: auto");
  }

  render() {
    // return (
    //   <>
    //     <div id="unity" style={{height: "100vh", width: 100 / 9 * 16 + "vh", margin: "auto"}}>
    //       <Unity unityContent={unityContent} />
    //     </div>
    //   </>);
    if (isMobile) {
      return (
        <>
          <DemoNavbar />
          <main ref="main">
            <div className="position-relative">
              {/* shape Hero */}
              <section className="section section-lg section-shaped pb-120">
                <div className="shape shape-style-1 shape-default">
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                  <span />
                </div>
                <Container className="shape-container d-flex align-items-center py-lg-md">
                  <div className="col px-0">
                    <Row className="align-items-center justify-content-center">
                      <Col className="text-center" lg="6">
                        <h1 className="display-3 text-white">
                          Supported Platforms{" "}
                        </h1>
                        <div className="btn-wrapper">
                          <Button
                              className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                              color="default"
                              href="/game"
                              disabled
                          >
                              <span className="btn-inner--icon mr-1">
                                  <i className="fas fa-gamepad" />
                              </span>
                              <span className="btn-inner--text">
                                  Browser
                              </span>
                          </Button>
                          <Button
                            className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                            color="default"
                            href="/download"
                            disabled
                          >
                              <span className="btn-inner--icon mr-1">
                              <i className="fab fa-apple" />
                              </span>
                              <span className="btn-inner--text">
                              App Store
                              </span>
                          </Button>
                          <Button
                            className="btn-white btn-icon mb-3 mb-sm-0 ml-1"
                            color="default"
                            href="/download"
                            disabled
                          >
                              <span className="btn-inner--icon mr-1">
                              <i className="fab fa-google-play" />
                              </span>
                              <span className="btn-inner--text">
                              Google Play
                              </span>
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Container>
              </section>
              <section className="section section-lg">
                  <Container>
                  <Row className="justify-content-center text-center mb-md">
                      <Col lg="8">
                      <h2 className="display-3">Coming soon...</h2>
                      </Col>
                  </Row>
                  <Row className="align-items-center justify-content-center">
                      <Col className="text-center" lg="6">
                          <div className="btn-wrapper">
                              <Button
                                  className="btn-white btn-icon btn-lg mb-4 mb-sm-0 ml-1"
                                  color="default"
                                  href="/download"
                                  disabled
                              >
                                  <span className="btn-inner--icon mr-1">
                                  <i className="fab fa-apple" />
                                  </span>
                                  <span className="btn-inner--text">
                                  App Store
                                  </span>
                              </Button>
                              <Button
                                  className="btn-white btn-icon btn-lg mb-3 mb-sm-0 ml-1"
                                  color="default"
                                  href="/download"
                                  disabled
                              >
                                  <span className="btn-inner--icon mr-1">
                                  <i className="fab fa-google-play" />
                                  </span>
                                  <span className="btn-inner--text">
                                  Google Play
                                  </span>
                              </Button>
                          </div>
                      </Col>
                  </Row>
                  </Container>
              </section>
            </div>
          </main>
          <SimpleFooter />
        </>
      );
    }
    return (
      <>
        <div id="unity" style={{height: "100vh", width: "100vw"}}>
          <Unity unityContent={unityContent} />
        </div>
        <div id="applixir_vanishing_div" hidden>
          <iframe id="applixir_parent" allowed="autoplay"></iframe>
        </div>
      </>);
  }
}

export default Game;